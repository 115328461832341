<template>
<body>
    <!-- ::::::  Start Header Section  ::::::  -->
    <Header/>
    <!-- :::::: End Header Section ::::::  -->  

    <!-- ::::::  Start  Breadcrumb Section  ::::::  -->
    <div class="page-breadcrumb">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <ul class="page-breadcrumb__menu">
                        <li class="page-breadcrumb__nav"> <router-link to="/">Home</router-link></li>
                        <li class="page-breadcrumb__nav active">Wishlist</li>
                    </ul>
                </div>
            </div>
        </div>
    </div> <!-- ::::::  End  Breadcrumb Section  ::::::  -->

    <!-- ::::::  Start  Main Container Section  ::::::  -->
    <main id="main-container" class="main-container inner-page-container">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-content">
                        <h5 class="section-content__title">Your Wishlist items</h5>
                    </div>
                    <!-- Start Wishlist Table -->
                    <div class="table-content table-responsive cart-table-content m-t-30">
                        <table>
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Product Name</th>
                                    <th>Price</th>
                                    <th>ADD TO CART</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="cart in Allproducts" :key="cart.id">
                                    <td class="product-thumbnail">
                                        <a href="#"><img class="img-fluid" :src="$root.URL_ROOT+'uploads/Product/'+cart.front_image" width="100%" alt="">
                                        </a>
                                    </td>
                                    <td class="product-name"><a href="#">{{cart.product_name}} </a></td>
                                    <td class="product-price-cart"><span class="amount">INR {{cart.selling_cost}}</span></td>
                                    <td class="product-add-cart">
                                        <a @click="add_to_cart(cart.pid,cart.product_name,'1',cart.selling_cost)" class="btn btn--box btn--small btn--radius btn--green btn--green-hover-black btn--uppercase font--semi-bold">ADD TO CART</a>
                                    </td>
                                     <td class="product-add-cart">
                                        <button type="button" @click="remove(cart.id)" data-toggle="tooltip" title="Delete" class="delete"><i class="fa fa-trash"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>  <!-- End Wishlist Table -->
                </div>
            </div>
        </div>
    </main> <!-- ::::::  End  Main Container Section  ::::::  -->
    <!-- ::::::  Start  Footer ::::::  -->
  <Footer/>
   <!-- ::::::  End  Footer ::::::  -->
</body>
</template>

<script>
import Header from "@/components/navbar";
import Footer from "@/components/footer";
export default {
    components: {
        Header,
        Footer
    },
    data() {
        return {
            Allproducts: [],
            loading: true,
            NoRecord: false,
            AllproductsShow: false,
            name: this.$route.params.name,
            uid: localStorage.getItem("user"),
        };
    },
    mounted() {
        this.getData();
        this.goToTop()
    },
    watch: {
        // Allproducts: function (id) {
        //     this.Allproducts = id;
        //     this.getData();
        // },
    },
    methods: {
         remove(id) {
            const axios = require("axios");
            var ev = this;
            var param = {
                id: id,
                table: 'mss_wishlist',
                type: 'commonDelete'
            }
            this.$swal({
                title: "Delete this ?",
                text: "Are you sure? You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: "#f00",
                confirmButtonText: "Yes, Delete it!",
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    axios({
                        method: 'POST',
                        url: this.$root.URL_ROOT + 'api.php',
                        data: param
                    }).then(function (response) {
                        var data = response.data;
                        ev.getData()
                        console.log(data)
                        // this.deleted = data
                        ev.$toasted.global.success({
                            message: 'Deleted successfully'
                        })
                    })
                }
            })
      },
        goToTop() {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        },
        getData() {
            const axios = require("axios");
            var ip_address = localStorage.getItem("ip_address");
            var user_session = localStorage.getItem("user");
            var uid = "";
            if (user_session != null) {
                uid = user_session;
            } else {
                uid = null;
            }
            var self = this;
            axios
                .post(this.$root.URL_ROOT + "api.php", {
                    type:'getMyWishList',
                    uid: uid,
                    ip: ip_address,
                })
                .then(function (response) {
                    var data = response.data;
                    if (data.status == 'success') {
                        self.AllproductsShow = true
                        self.Allproducts = data.data;
                    } else {
                        self.NoRecord = true
                        self.AllproductsShow = false
                    }
                });
        },
    },
};
</script>

<style>
.color-div {
    width: 50px;
    height: 50px;
}
</style>
